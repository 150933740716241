import React, { FC, useState } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../../components/seo'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ContentArea,
  PageTitle,
  TitleBorder,
  PillBadge
} from '@bscs-dev-team/bscs-design-system-core'

import FilterByDropdown from '../../components/filter-by-dropdown/filter-by-dropdown'
import FilterByRow from '../../components/filter-by-row/filter-by-row'
import Layout from '../../components/layout/layout'
import SearchBy from '../../components/search-by/search-by'

import { Frontmatter } from '../../templates/template-types'


type Node = {
  id: string,
  excerpt: string,
  frontmatter: Frontmatter
}

type Edge = {
  node: Node
}

type AllMdx = {
  edges: Edge[]
}

type Data = {
  allMdx: AllMdx
}

type NewsPageProps = {
  data: Data
}

type FilterItems = {
  filters: (string | string[] | boolean)[]
}

const NewsPage: FC<NewsPageProps> = ({ data }: NewsPageProps) => {
  const [activeFilters, setActiveFilters] = useState<string[]>([])

  const newsletters: Edge[] = data.allMdx.edges

  const filterItems: FilterItems = {
    filters: ["Filter", false, ["Coverage", "Announcement", "Newsletter"]]
  }

  return (
    <React.Fragment>
      <SEO
        title="Research & Development In Science Education - BSCS News"
        description="BSCS Science Learning is an independent nonprofit offering research and development in science education across the US."
        canonicalUrl="https://bscs.org/our-work/news/"
      />
      <Layout>
        <section className="section">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className='inline-anchor' to='/'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              Our Work
            </BreadcrumbItem>
            <BreadcrumbItem>
              News
            </BreadcrumbItem>
          </Breadcrumb>
          <PageTitle>News</PageTitle>
          <TitleBorder />
        </section>
        <section className="section">
          <div>
            <div className="sm:flex">
              <div className="py-2 pl-0 pr-2">
                <SearchBy />
              </div>
              <div className="py-2 pl-2 pr-0 ml-auto">
                <FilterByDropdown
                  items={filterItems}
                  activeFilters={activeFilters}
                  setActiveFilters={(activeFilters) => setActiveFilters(activeFilters)}
                />
              </div>
            </div>
            {activeFilters.length > 0 &&
              <FilterByRow
                activeFilters={activeFilters}
                setActiveFilters={(activeFilters) => setActiveFilters(activeFilters)}
              />
            }
            <hr />
          </div>
        </section>
        <section className="section">
          <div>
            <div className="mb-3">
              {
                newsletters.map((edge, index) => {
                  let dataFilter = JSON.parse(JSON.stringify(edge.node.frontmatter))

                  dataFilter['excerpt'] = edge.node.excerpt

                  const variants: Record<string, string> = {
                    'Announcement': 'yellow',
                    'Coverage': 'indigo',
                    'Newsletter': 'green'
                  }

                  return (
                    <ContentArea
                      id="parent"
                      key={edge.node.id}
                      size="xl"
                    >
                      <div
                        id={`news-${index}`}
                        data-filter={JSON.stringify(dataFilter)}
                      >
                        <h3>{edge.node.frontmatter.title}</h3>
                        <p>
                          {edge.node.frontmatter.cardDescription}
                          {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                        </p>
                        <p className="italic font-serif">
                          {edge.node.frontmatter.date}
                        </p>
                        <PillBadge
                          variant={variants[edge.node.frontmatter.category]}
                        >
                          {edge.node.frontmatter.category}
                        </PillBadge>
                        <div className="flex items-end justify-end w-full">
                          <Link
                            to={`/our-work/news/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                          >
                            <Button variant="indigo" title="Read More">Read More</Button>
                          </Link>
                        </div>
                      </div>
                    </ContentArea>
                  )
                })
              }
            </div>
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default NewsPage

export const newsQuery = graphql`
  query newsQuery {
    allMdx(
      filter: {frontmatter: { status: {eq: "Ready"}, page: {eq: "news"}}}
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            additionalTags
            category
            cardDescription
            date(formatString: "MMMM DD, YYYY")
            page
            title
          }
        }
      }
    }
  }
`
